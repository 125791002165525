<template>
<div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 pa-2 text-left>
        <span class="itemHeading">Performance Management Review</span>
      </v-flex>
        <v-flex align-self-center xs12 v-if="employeeList">
                 <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">No.</th>
                                      <th class="text-left">Financial Year</th>
                                      <th class="text-left">Name</th>
                                      <th class="text-left">Rating</th>
                                      <th class="text-left">Delete</th>
                                      <!-- <th class="text-left">View</th> -->
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(item, i) in employeeList" :key="i">
                                      <td class="text-left">
                                        {{ i + 1 }}
                                        <!-- <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span> -->
                                      </td>
                                      <td class="text-left">
                                        {{ item.fyear }}
                                      </td>
                                      <td class="text-left">
                                        {{ item.employeeId.name }}
                                      </td>
                                        <td class="text-left">
                                        {{ item.rating }}
                                      </td>
                                      <td class="text-left">
                                        <v-icon
                                          small
                                          @click="
                                            (deleteDialog = true),
                                              (curitem = item._id)
                                          "
                                          >mdi-delete</v-icon
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                                <v-dialog persistent v-model="deleteDialog" max-width="600px">
              
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                       data?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteDialog = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem()"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
        </v-flex>
        <v-flex v-else align-self-center xs12>
          <span>No data found</span>
        </v-flex>
    </v-layout>
</div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
        showsnackbar:false,
      appLoading: false,
      employeeList:[],
      deleteDialog:false,
      curitem:null,
        };
  },
  mounted() {
    this.getData();
  },
    methods: {
         getData() {
      this.appLoading = true;
      axios({
        url: "/pmr/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.employeeList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
      deleteItem() {
      var data = {};
      data["id"] = this.curitem;
      axios({
        url: "/pmr/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;  
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.deleteDialog=false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    },
  };
</script>